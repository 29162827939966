import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/30-60-90-plan.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { ButtonContainer, Contact } from "../../components/button/Button.js"



function ContentPlan306090({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="30-60-90 Day Content Marketing Plan" 
       description="This template includes a step-by-step plan for your first 90 days in a new company. The plan is structured in four different phases, to help you stay organized while uncovering the big picture."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
          <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/30-60-90-day-plan-template/">30-60-90 Day Content Marketing Plan</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
             <BlogTitle isPrimary title="30-60-90 Day Content Marketing Plan" />
            <SubHeading subheading="This template includes a step-by-step plan for your first 90 days in a new company." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/30-60-90-day-content-marketing-plan" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="30-60-90 Day Content Marketing Plan" />
          </Column50>
        </Row>
      </AboveFold>

      <Section isCentered>
        <BlogRow isCentered>
          <TextMedium textMedium="The plan is structured in four different phases, to help you stay organized while uncovering the big picture."/> 
          <TextMedium textMedium="We’ve used this template to develop and implement content / inbound marketing strategies for SaaS and service companies of various sizes. As a prerequisite, you should have your brand and overall marketing strategy already in place, as this document doesn't cover the brand and marketing strategy part." />
          <GumroadForm 
                formName="30-60-90 Day Content Marketing Plan"
                redirectPage="https://socontent.gumroad.com/l/30-60-90-day-content-marketing-plan"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/30-60-90-day-content-marketing-plan"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentPlan306090